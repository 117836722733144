import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Accordeon from "App/components/Accordeon";

export default class Ajustement extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();
  }

  get className(){
    return "Page Page--ajustement";
  }

  render () {
    const html = $('script[name="ajustement"]').html();
    this.$el.html(html);

    this.$('.accordeon').each(function(){
      new Accordeon($(this));
    })

  }


}

import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";

export default class Home extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;
  }

  get index(){
    return 0;
  }

  get className(){
    return "Page Page--home";
  }

  render () {
    const html = $('script[name="home"]').html();
    this.$el.html(html);
  }

  afterRender(){

  }


}

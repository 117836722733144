import $ from 'jquery';
import {TimelineLite,TweenLite,CSSPlugin,EasePack} from 'gsap';

export default class Tabs{
  constructor(el){

    const refthis = this;

    this.$el = el;
    this.$navs = $('.tabs-nav',this.$el);
    this.$content = $('.tabs-hidden',this.$el);
    this.$btn = $('.tabs-btn',this.$el);
    this.$tabInfo = $('.tabs-info',this.$el);
    // this.tl = new TimelineLite();




    $(document).ready(function(){
      refthis.init();
    });
  }

  init(){
    const refthis = this;



    this.$navs.click(function(e){
      const $this = $(this);
      const index = $this.index();
      const $current = refthis.$content.eq(index);

      refthis.$navs.removeClass('isActive');
      $this.addClass('isActive');
      refthis.$content.removeClass('isActive');
      $current.addClass('isActive');
      return false;
    });


      let h = this.$tabInfo.innerHeight();
      this.$tabInfo.css('height','0');

      this.$btn.click(function(e){
        const $this = $(this);
        let $info = $this.find('.tabs-info');

        $info.add($this).toggleClass('isActive');

        if($info.hasClass('isActive')){
          TweenLite.to($info,0.35,{height:h,display:'block',force3D: true},0)
        }else{
          TweenLite.to($info,0.35,{height:0,force3D: true,onComplete:()=>{
            $info.css('display','none');
          }},0)
        }
        return false;
      });

  }
}

import $ from 'jquery';
import Router from 'App/router/Router';
// import {TimelineLite,TweenLite,CSSPlugin,EasePack} from 'gsap';

class Menu{
  constructor(){

    const refthis = this;

    this.$el = $('.SideMenu');
    this.$button = $('.button--menu');
    this.$secondMenu = $('.second-menu');
    this.$firstMenu = $('.first-menu');
    this.$item = $('li a',this.$el);
    // this.tl = new TimelineLite();
    Router.on('route',(e,d)=>{
      this.$item.parent('li').removeClass('isActive');
      this.$currentItem = $(`li a[href="#${Router.pageName}"]`);
      this.$currentItem.parent('li').addClass('isActive');
    });


    $(document).ready(function(){
      refthis.init();
    });

    // refthis.resize();
    //
    // $(window).resize(()=>{
    //   refthis.resize();
    // });
  }

  init(){
    const refthis = this;

    this.$button.click(function(e){

      if(refthis.$el.hasClass('isOpen')){
        TweenLite.to(refthis.$el,0.45,{className:'-=isOpen',onComplete:()=>{
          refthis.$secondMenu.css('pointer-event','none');
        }})
      }else{
        TweenLite.to(refthis.$el,0.45,{className:'+=isOpen',onComplete:()=>{
          refthis.$secondMenu.css('pointer-event','initial');
        }})
      }
       e.preventDefault();
    });

    this.$item.click(function(e){
      refthis.$el.removeClass('isOpen');
    });
  }
  //
  // resize(){
  //   let secondeMenuH = this.$secondMenu.height();
  //   this.$firstMenu.height(secondeMenuH);
  // }


}

export default new Menu();

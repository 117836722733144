import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Data from 'App/data/Data';

import Backbone from "backbone"

export default class Conclusion extends PageAbstract {
  
  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();
  }

  get index(){
    return 6;
  }

  get className(){
    return "Page Page--conclusion";
  }

  render () {
    const html = $('script[name="conclusion"]').html();
    this.$el.html(html);

    

  }

  initGlobalData(){

    const $input = this.$('.switch-checkbox');
    
    // Permet de cocher la checkbox si on change de page.
    if(Data.conclusion.resultatVariation ==  Data.solutionTrilatte.total){
      $input[0].checked = true;
    }

    const refthis = this;
    this.$("[data-id]").each(function(){
      const $this = $(this);
      const dataID = $this.attr("data-id");
      const dataValue = Data.get(dataID);
      const data = (typeof dataValue == "number" ) ? Number(dataValue).toLocaleString().replace(/,/g, '.') : dataValue ;

      if(($this.is('input') || $this.is('select'))){
        $this.val(dataValue);
      }else{
        $this.text(data)
      }
    });
  }

}

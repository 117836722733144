import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Backbone from "backbone"


export default class Client extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();

  }

  get prevPage(){
    return 0;
  }
  get index(){
    return 1;
  }

  get className(){
    return "Page Page--client";
  }

  render () {
    const html = $('script[name="client"]').html();
    this.$el.html(html);
  }


}

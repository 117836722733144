import $ from 'jquery';
import {TimelineLite,TweenLite,CSSPlugin,EasePack} from 'gsap';

export default class Accordeon{
  constructor(el){

    const refthis = this;

    this.$el = el;
    this.$header = $('.accordeon-header',this.$el);
    this.$content = $('.accordeon-hidden',this.$el);
    this.$parameters = $('.accordeon-parameters',this.$el);
    this.$btnParameters = $('.accordeon-btn--parameters',this.$el);
    // this.tl = new TimelineLite();

    $(document).ready(function(){
      refthis.init();
    });
  }

  init(){
    const refthis = this;
    this.h = this.$content.height();
    this.ph = this.$parameters.innerHeight();
    this.$content.add(this.$parameters).css('height','0');

    if(this.$el.hasClass('isActive')){
      this.open();
    }else{
      this.close();
    }

    this.$header.click(function(e){
      const $this = $(this);

      if(refthis.tl && refthis.tl.isActive()){
       e.preventDefault();
       e.stopImmediatePropagation();

       return false;
      }﻿

      refthis.$el.toggleClass('isActive');

      if(refthis.$el.hasClass('isActive')){
        refthis.open($this);
      }else{
        refthis.close($this);
      }

      return false;
    });

    this.$btnParameters.click(function(e){
      const $this = $(this);
      if(refthis.tl && refthis.tl.isActive()){
       e.preventDefault();
       e.stopImmediatePropagation();

       return false;
      }
      
      $(this).toggleClass('isActive');
      refthis.$parameters.toggleClass('isActive');

      if(refthis.$parameters.hasClass('isActive')){
         refthis.openParameters();
       }else{
         refthis.closeParameters();
       }

       return false;
    });
  }

  open(el){
    console.log(el);
    const refthis = this;
    this.tl = new TimelineLite({
      onStart:()=>{
        TweenLite.set(refthis.$content,{display:'block'});
      }
    })
      .to(this.$el,0.35,{className:'+=isOpen',force3D: true})
      .to(this.$content,0.35,{height:this.h,force3D: true},0)

      return this.tl;
  }

  close(){
    const refthis = this;
    this.tl = new TimelineLite({
      onComplete:()=>{
        TweenLite.set(refthis.$content,{display:'none'});
      }
    })
      .to(this.$el,0.35,{className:'-=isOpen',force3D: true})
      .to(this.$content,0.35,{height:0,force3D: true},0)

    return this.tl;
  }

  openParameters(el){
    const refthis = this;
    this.tl = new TimelineLite({
      onStart:()=>{
        TweenLite.set(refthis.$parameters,{display:'block'});
      }
    })
      .to(this.$parameters,0.35,{className:'+=isOpen',force3D: true})
      .to(this.$parameters,0.35,{height:this.ph,force3D: true},0)

      return this.tl;
  }

  closeParameters(){
    const refthis = this;
    this.tl = new TimelineLite({
      onComplete:()=>{
        TweenLite.set(refthis.$parameters,{display:'none'});
      }
    })
      .to(this.$parameters,0.35,{className:'-=isOpen',force3D: true})
      .to(this.$parameters,0.35,{height:0,force3D: true},0)

    return this.tl;
  }
}

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Router from "App/router/Router";

import Home from 'App/views/Home';
import Client from 'App/views/Client';
import CalculTradi from 'App/views/CalculTradi';
import calculTrilatte from 'App/views/CalculTrilatte';
import Comparatifs from 'App/views/Comparatifs';
import Rendement from 'App/views/Rendement';
import Ajustement from 'App/views/Ajustement';
import Conclusion from 'App/views/Conclusion';
import Reglages from 'App/views/Reglages';
import Menu from 'App/components/Menu';
import Data from 'App/data/Data';

export default class Application extends Backbone.View {
    el(){
      return $("#app");
    }
    initialize () {
      // Initialisation de l'historique
      this.listenTo(Router, "route", this.changePage);
      Backbone.history.start({pushState: false})
      $('body').addClass(Backbone.history.getFragment());

    }
    changePage(){

      let currentRoute = Backbone.history.getFragment();
      let page;
      //
      switch(currentRoute){
    		case "client":
    		  page = new Client();
  		    break;
    		case "calcul-traditionelle":
    		page = new CalculTradi();
    		break;
    		case "calcul-trilatte":
    		page = new calculTrilatte();
    		break;
        case "resultats-comparatifs":
    		page = new Comparatifs();
    		break;
        case "rendement":
    		page = new Rendement();
    		break;
        case "ajustement":
    		page = new Ajustement();
    		break;
        case "conclusion":
    		page = new Conclusion();
    		break;
        case "reglages":
    		page = new Reglages();
    		break;
    		default :
    		  page = new Home();
      //
    	}
      if(!page){return;}

      if(currentRoute == ""){
        currentRoute = "homePage";
      }
      this.$el.append(page.$el);

      this.tl = new TimelineLite({
        onSart:()=>{
        },
        onComplete:()=>{
          $('body').addClass(currentRoute);
        }
      });
      if(this.page){
        $('body').removeClass();

        // $('body').removeClass(this.class)
        // const tl = this.page.hide();
        // this.tl.add(tl);


        if(page.index > this.page.index){
          const tl = this.page.hide();
          this.tl.add(tl);

          this.tl.add(page.show(),0.3);
        }else{
          const tl = this.page.hideReverse();
          this.tl.add(tl);

          this.tl.add(page.showReverse(),0.3);
        }
      }


      // this.tl.add(page.show(), 0);
      this.page = page;

      //
      //
      // Data.set("membrane.mo",50.00);
      //
      // console.log(Data.get("membrane.mo"));
      //
      // console.log(Data.data);
      // Permet d'ajouter une class à l'app pour changer la couleur du logo.

      this.class= currentRoute;

      const tlTransition = new TimelineLite();
      this.tl.to(window,0.5,{scrollTo:0,autoKill:false},0);
      this.tl.add(()=>{},0.5)


      // this.tl.pause();
      // page.initData('laineVerre');
      // page.initData('membrane');
      // page.initData('placo');


    }
}

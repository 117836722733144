import $ from 'jquery';

// let url = new URL(window.location);
// const DEBUG_CENTS = url.searchParams.get("debug_cents") == "true";
const DEBUG_CENTS = false;
/* Class qui contient les items avec fourniture et main d'oeuvre */
class ObjFournitureMO{
  constructor (fourniture, mo){
      this._fourniture = fourniture;
      this._mo = mo;
  }

  get fourniture(){
    return arround(this._fourniture);
  }
  set fourniture(n){
    this._fourniture = arround(n);
  }

  get mo(){
    return arround(this._mo);
  }
  set mo(n){
    this._mo = arround(n);
  }


  get total(){
    return arround(1*this.fourniture  + 1*this.mo);
  }


}

/* class par défaut */
class ObjDefault{
  constructor(value){
      this._value = value;
  }

  get value(){
    return arround(this._value)
  }

  set value(value){
    this._value = arround(value);
  }
}


/* class information client */
class ObjClient{
  constructor(tauxHoraire,heuresJour,tauxMarge){
    this._tauxHoraire = tauxHoraire;
    this._heuresJour = heuresJour;
    this._tauxMarge = tauxMarge;
    /* Minutes par défaut */
    this._typeTemps = DEBUG_CENTS ? "centièmes" : "minutes";
  }

  get tauxHoraire(){
    return arround(this._tauxHoraire);
  }

  get heuresJour(){
    return arround(this._heuresJour);
  }

  get tauxMarge(){
    return arround(this._tauxMarge);
  }

  get typeTemps(){
    return this._typeTemps;
  }

  /**
   * Le nb de minutes ou centiemes par heure
   */
  get uniteTemps(){
    return this._typeTemps == "minutes" ? 60 : 100;
  }
  get typeTempsInvert(){
    if(this.typeTemps == "minutes"){
      return "centièmes";
    }else{
      return "minutes";
    }
  }

  set tauxHoraire(value){
    this._tauxHoraire = arround(value);
  }
  set heuresJour(value){
    this._heuresJour = arround(value);
  }
  set tauxMarge(value){
    this._tauxMarge = arround(value);
  }
  set typeTemps(value){
    this._typeTemps = value;
  }

  conversionMainOeuvre(coutMainOeuvre){
    return (this.typeTemps == "minutes") ? coutMainOeuvre/60 : coutMainOeuvre/100;
  }
}


/* class page calcul traditionelle */
class DataTraditonelle{
  constructor(fibreBois,laineVerre,membrane,placo,bois,pointes,ecran,liteaux,platre,accessoires,dataClient,surfaceChantier){
    this.membrane = membrane;
    this.placo = placo;
    this.accessoires = accessoires;
    const client = this.client = dataClient
    this.surfaceChantier = surfaceChantier;

    const refthis = this;
    /* Objet chevron comprennant m3 & m2 */
    this.chevron = {
      bois : {
        _percent : 8,
        get percent(){return arround(this._percent);},
        set percent(n){ this._percent = arround(n);}
      },
      pointes: {
        _kgM3 : 3,
        get kgM3(){return arround(this._kgM3);},
        set kgM3(n){this._kgM3 = arround(n);}
      },
      percentMarge: {
        _taux : null,
        get taux(){
          if(this._taux){return this._taux;}
          return client.tauxMarge;
        },
        set taux(n){this._taux = arround(n);}
      },
      _coutMainOeuvre: DEBUG_CENTS ? 1833.33 : 1100,
      get coutMainOeuvre(){return arround(this._coutMainOeuvre);},
      set coutMainOeuvre(n){this._coutMainOeuvre = arround(n);},
      // get conversionMainOeuvre(){ return (client.typeTemps == "minutes") ? this.coutMainOeuvre / 60 : this.coutMainOeuvre / 100; },
      section: {
        _lar: 0.08,
        get lar(){return arround(this._lar);},
        set lar(n){this._lar = arround(n);},
        _lng: 0.11,
        get lng(){return arround(this._lng);},
        set lng(n){this._lng = arround(n);},
        _entreAxe: 0.6,
        get entreAxe(){return arround(this._entreAxe);},
        set entreAxe(n){this._entreAxe = arround(n);},
      },

      /**
      ** @return Number : Prix total du bois pour m3
      **/
      get totalBois(){
        const percent = this.bois.percent;
        return arround(bois.value * ( 1 + percent / 100));
      },

      /**
      ** @return Number : Prix total des pointes
      **/
      get totalPointes(){
        const nb = this.pointes.kgM3;
        return arround(pointes.value * nb);
      },

      /**
      ** @return Number : Prix total des pointes
      **/
      get totalPointesM2(){
        const nb = this.pointes.kgM3;
        return arround((pointes.value * nb/10));
      },

      /**
      ** @return Number : marge sur les materiaux pour les chevrons au m3
      **/
      get margeMateriaux() {
        const nb = this.percentMarge.taux;
        // const total = Number(1*this.totalBois + 1*this.totalPointes);
        return arround(this.coutMateriaux * nb / 100);
      },

      /**
      ** @return Number : marge sur les materiaux pour les chevrons au m2
      **/
      get margeMateriauxM2(){
        const nb = this.percentMarge.taux;
        return arround(this.coutMateriauxM2 * nb / 100);
      },


      get coutMateriaux(){
        return arround(1*this.totalBois + 1*this.totalPointes);
      },

      get coutMateriauxM2(){
        return arround(1*this.totalEntraxe + 1*this.totalPointesM2);
      },
      /**
      ** @return Number : prix des materiaux avec la marge d'erreur.
      **/
      get totalMateriaux() {

        return arround(1*this.coutMateriaux + 1*this.margeMateriaux);
      },

      /**
      ** @return Number : prix des materiaux avec la marge d'erreur pour les chevrons au m2
      **/
      get totalMateriauxM2() {
        return arround(1*this.coutMateriauxM2 + 1*this.margeMateriauxM2);
      },

      /**
      ** @return Number : cout main d'oeuvre au m2.
      **/
      get coutMainOeuvreM2() {
        return arround(this.entraxeM3M2 * this.coutMainOeuvre);
      },

      /**
      ** @return Number : prix de la main d'oeuvre en minutes ou centième pour chevrons au m3
      **/
      get totalMainOeuvre() {
          return arround(client.conversionMainOeuvre(this.coutMainOeuvre) * client.tauxHoraire);
      },

      /**
      ** @return Number : prix de la main d'oeuvre en minutes ou centième pour chevrons au m2
      **/
      get totalMainOeuvreM2() {
        return arround(client.conversionMainOeuvre(this.coutMainOeuvreM2) * client.tauxHoraire);
      },


      /**
      ** @return Number : Prix total des chevrons au m3
      **/
      get total(){
        return arround(1*this.totalMateriaux + 1*this.totalMainOeuvre);
      },

      /**
      ** @return Number : Prix total des chevrons au m2
      **/
      get totalM2(){
        return arround(1*this.totalMateriauxM2 + 1*this.totalMainOeuvreM2);
      },

      /**
      ** @return Number : Volume de bois par m3 en fonction de la section et de l'entraxe.
      **/
      get entraxeM3M2(){
        return Number((1 / this.section.entreAxe)*(this.section.lar * this.section.lng)).toFixed(3);
      },

      /**
      ** @return Number : retourne le prix du volume de bois par m3
      **/
      get totalEntraxe(){
        const total = 1*this.totalBois + 1*this.totalPointes;
        return arround(this.entraxeM3M2 * total);
      }
    }
    /* Objet ecran & sous-toiture */
    this.sousToiture = {
      ecran:{
        _percent : 8,
        get percent(){return arround(this._percent);},
        set percent(n){this._percent = arround(n);}
      },
      pointes: {
        _kgM3 : 0.3,
        get kgM3(){return arround(this._kgM3);},
        set kgM3(n){this._kgM3 = arround(n);}
      },
      percentMarge: {
        _taux : null,
        get taux(){
          if(this._taux){return this._taux;}
          return client.tauxMarge;
        },
        set taux(n){this._taux = arround(n);}
      },
      _coutMainOeuvre: DEBUG_CENTS ? 11.67 : 7,
      get coutMainOeuvre(){return arround(this._coutMainOeuvre);},
      set coutMainOeuvre(n){this._coutMainOeuvre = arround(n);},

      /**
      ** @return Number : Prix total du pare pluie au m²
      **/
      get prixEcran(){
        const percent = this.ecran.percent;
        return arround(ecran.value * ( 1 + percent / 100));
      },

      /**
      ** @return Number : taille en ml/m² des contre liteau en fonction de l'entraxe.
      **/
      get liteauxMl(){
        const entreAxe = refthis.chevron.section.entreAxe;
        return arround(1/entreAxe);
      },

      /**
      ** @return Number : prix au /m² des contre liteaux
      **/
      get prixLiteaux(){
        return arround(liteaux.value * this.liteauxMl);
      },

      /**
      ** @return Number : kg/m2 des pointes
      **/
      // get pointeskgM3(){
      //   const pointesm3 = refthis.chevron.pointes.kgM3;
      //   return arround(pointesm3 / 10);
      // },

      /**
      ** @return Number : prix pointes au kg/m2
      **/
      get prixPointes(){
        const pointesm3 = this.pointes.kgM3;
        return arround(pointes.value * pointesm3);
      },

      /**
      ** @return Number : prix total des materiaux de sous toiture au m2
      **/
      get margeMateriaux(){
        const nb = this.percentMarge.taux;
        const total = 1*this.prixEcran + 1*this.prixLiteaux + 1*this.prixPointes;
        return arround(total * nb / 100)
      },

      /**
      ** @return Number : prix total des materiaux de sous toiture au m2
      **/
      get totalMateriaux(){
        const total = 1*this.prixEcran + 1*this.prixLiteaux + 1*this.prixPointes;
        return arround(total + 1*this.margeMateriaux);
      },

      /**
      ** @return Number : prix de la main d'oeuvre en minutes ou centième pour ecran & sous-toiture.
      **/
      get totalMainOeuvre() {
        return arround(client.conversionMainOeuvre(this.coutMainOeuvre) * client.tauxHoraire);
      },

      /**
      ** @return Number : Prix total des element de sous-toiture au m2
      **/
      get total(){
        return arround(1*this.totalMateriaux + 1*this.totalMainOeuvre);
      },

    }
    /* Objet isolation */
    this.isolation = {
      _isolant : "laine_verre",
      get isolant() {
        return this._isolant
      },
      set isolant(n){
        this._isolant = n;
      },
      get prix(){
        if(this.isolant == 'laine_verre'){
          return laineVerre.total;
        }else{
          return fibreBois.total;
        }
      },
      get epaisseur(){
        if(this.isolant == 'laine_verre'){
          return 240;
        }else{
          return 230;
        }
      }
    }

    this.echaffaudage = {
      _prix: 3.00,
      get prix(){return arround(this._prix);},
      set prix(n){this._prix = arround(n);},
    }

  }

  get total(){
    return arround(Number(this.chevron.totalM2) + Number(this.sousToiture.total) + Number(this.isolation.prix) + Number(this.membrane.total) + Number(this.placo.total) + Number(this.echaffaudage.prix));
  }

  get margeMateriauxParM2(){

  }

  get CAEntrepriseParM2(){
    const total = arround(1*this.sousToiture.total + 1*this.chevron.totalM2);
    return arround(total * this.surfaceChantier.value);
  }

  get totalTmpsMiseEnOeuvre(){
    // en minutes
    let coutMainOeuvre = Number(this.chevron.coutMainOeuvreM2) + Number(this.sousToiture.coutMainOeuvre);
    if(this.client.typeTemps != "minutes"){
      coutMainOeuvre *= .6;
    }
    return coutMainOeuvre * this.surfaceChantier.value;
  }

  get stringTmpsMiseEnOeuvre(){
    return convertMinutesToDayString(this.totalTmpsMiseEnOeuvre);
  }

}

function convertCentsToDayString(time){
  return convertMinutesToDayString(time / 100 * 60);
}

function convertMinutesToDayString(time){

  time = Math.round(time);

  // const days = Math.floor(time / 1440);
  // time -= days * 1440;
  const hours = Math.floor(time / 60);
  time -= hours * 60;
  const minutes = Math.floor(time);
  time -= minutes;

  let ret = [];
  // if(days > 0){
  //   const txt = (days > 1) ? 'jours' : 'jour';
  //   ret.push(`${days} ${txt}`);
  // }

  if(hours > 0){
    time -= hours * 60;
    ret.push(`${hours} h`);
  }

  if(minutes > 0){
    time -= minutes;
    ret.push(`${minutes} min`);
  }

  // const hours = Math.floor(time/60);
  // const min = time - hours * 60;
  return ret.join(' ');

}

/* class page calcul trilatte */
class DataTrilatte{
  constructor(platre,accessoires,client){
    this.platre = platre;
    this.accessoires = accessoires,
    this.client = client;
    this._tmpsPose = DEBUG_CENTS ? 40 : 24;

    this.percentMarge = {
      _taux : null,
      get taux(){
        if(this._taux){return this._taux;}
        return client.tauxMarge;
      },
      set taux(n){this._taux = arround(n);}
    }
  }


  get tmpsPose(){
    return arround(this._tmpsPose);
  }
  set tmpsPose(n){
    this._tmpsPose = arround(n);
  }

  /**
  ** @return Number : marge sur les materiaux
  **/
  get margeMateriaux(){
    const nb = this.percentMarge.taux;
    const total = 1*this.platre.value + 1*this.accessoires.value;
    return arround(total * (nb / 100));
  }

  /**
  ** @return Number : prix des materiaux avec la marge d'erreur.
  **/
  get totalMateriaux() {
    const total = 1*this.platre.value + 1*this.accessoires.value;
    return arround(total + 1*this.margeMateriaux);
  }

  get totalMainOeuvre() {
      return arround(this.client.conversionMainOeuvre(this.tmpsPose) * this.client.tauxHoraire);
  }

  /**
  ** @return Number : Prix total des elements.
  **/
  get total(){
    return arround(1*this.totalMateriaux + 1*this.totalMainOeuvre);
  }






}

/* class page calcul comparatifs */
class DataComparatifs{
  constructor(solutionTradi,solutionTrilatte,membrane,surfaceChantier){
    this.solutionTradi = solutionTradi;
    this.solutionTrilatte = solutionTrilatte;
    this.surfaceChantier = surfaceChantier;
    this.membrane = membrane;
    this._etancheite = 'oui';
  }

  get etancheite(){
    return this._etancheite;
  }
  set etancheite(n){
    this._etancheite = n;
  }

  get margeMateriauxTradi(){
    const total = 1*this.solutionTradi.sousToiture.margeMateriaux + 1*this.solutionTradi.chevron.margeMateriauxM2;
    return arround(total * this.surfaceChantier.value);
  }

  get totalM2Tradi(){
    if(this.etancheite == 'non'){
      return arround(this.solutionTradi.total - this.membrane.total);
    }else{
      return arround(this.solutionTradi.total);
    }
  }

  get totalM2Trilatte(){
    return this.solutionTrilatte.total;
  }

  get margeMateriauxTrilatte(){
      return arround(this.solutionTrilatte.margeMateriaux * this.surfaceChantier.value);
  }

  get gain(){
    return arround(this.margeMateriauxTrilatte - this.margeMateriauxTradi);
  }


}


// /* class page rendement */
class DataRendement{
  constructor(client,solutionTrilatte,platre,accessoires,surfaceChantier){
    this.client = client;
    this.solutionTrilatte = solutionTrilatte;
    this._nbOuvrier = 3;
    this.platre = platre;
    this.accessoires = accessoires;
    this._coutMainOeuvreS2 = 15.00;
    this._m2JourPersonneAjuste = 33;
    this._tauxHoraireAjuste = 43.00;
    this.surfaceChantier = surfaceChantier;
  }


  get margeMateriaux(){
    const total = 1*this.platre.value + 1*this.accessoires.value;
    return arround(total * this.client.tauxMarge / 100);
  }

  get margeMateriauxParM2(){
    return arround(this.margeMateriaux * this.surfaceChantier.value);
  }

  /**
   * Getteurs & setteur informations de base
   */
  get coutMainOeuvreBasic(){
    return arround((this.solutionTrilatte.tmpsPose / this.client.uniteTemps) * this.client.tauxHoraire);
  }
  get m2JourPersonneBasic(){
    return arround((this.client.heuresJour * this.client.uniteTemps) / this.solutionTrilatte.tmpsPose);
  }
  get totalPoseBasic(){
    return arround(this.nbOuvrier * this.m2JourPersonneBasic);
  }

  /**
   * Getteurs & setteur informations ajustées
   */
  get nbOuvrier(){
    return arround(this._nbOuvrier);
  }
  set nbOuvrier(n){
    this._nbOuvrier = arround(n);
  }


  get m2JourPersonneAjuste(){
    return arround(this._m2JourPersonneAjuste);
  }
  set m2JourPersonneAjuste(n){
    this._m2JourPersonneAjuste = arround(n);
  }
  get tauxHoraireAjuste(){
   return arround(this._tauxHoraireAjuste);
  }
  set tauxHoraireAjuste(n){
    this._tauxHoraireAjuste = arround(n);
  }
  get coutMainOeuvreAjuste(){

    return arround(this.tmpsPose * this.tauxHoraireAjuste / this.client.uniteTemps);
    if(this.client.typeTemps == "minutes"){
      const time = minuteTocentieme(this.tmpsPose)
      return arround(time * this.tauxHoraireAjuste);
    }else{
      return arround(this.tmpsPose * this.tauxHoraireAjuste);
    }
  }

  get totalPoseAjuste(){
    return arround(this.nbOuvrier * this.m2JourPersonneAjuste);
  }
  get totalAjuste(){
    return arround(1*this.solutionTrilatte.totalMateriaux + 1*this.coutMainOeuvreAjuste);
  }

  get tmpsPose(){
    return arround((1 / (this.m2JourPersonneAjuste / this.client.heuresJour)) * this.client.uniteTemps);
  }

  get tauxHoraireS2(){
    return arround(this.coutMainOeuvreBasic / this.tmpsPose * this.client.uniteTemps);
  }
  //



}


class DataConclusion{
  constructor(resultat,surfaceChantier,client,solutionTradi,solutionTrilatte,variation){
    this.variation = variation;
    this.resultat = resultat;
    this.client = client;
    this.solutionTradi = solutionTradi;
    this.solutionTrilatte = solutionTrilatte;
    this.surfaceChantier = surfaceChantier;
    this._resultatVariation = this.variation.totalAjuste;
  }

  get resultatVariation(){
    return this._resultatVariation;
  }
  set resultatVariation(e){


    // if(this._resultatVariation == this.variation.totalAjuste){
    //   console.log(this._resultatVariation);
    //   this._resultatVariation = this.solutionTrilatte.total;
    // }else{
    //   console.log(this._resultatVariation);
    //   this._resultatVariation = this.variation.totalAjuste;
    // }
    if(this._resultatVariation == this.variation.totalAjuste){
      this._resultatVariation = this.solutionTrilatte.total;
    }else{
      this._resultatVariation = this.variation.totalAjuste;
    }
  }


  /* Resultat traditionelle */
  get coutChantierTradi(){
    return arround(this.resultat.totalM2Tradi * this.surfaceChantier.value);
  }

  /* Resultat trilatte */
  get coutChantierTrilatte(){
    return arround(this.resultatVariation * this.surfaceChantier.value);
  }

  get totalTmpsMiseEnOeuvreTrilatte(){
    // en minutes
    return this.variation.coutMainOeuvreAjuste * this.surfaceChantier.value;
  }

  get stringTmpsMiseEnOeuvretrilatte(){
    return convertMinutesToDayString(this.totalTmpsMiseEnOeuvreTrilatte);
  }

  /* Resultats */
  get percentChiffreAffaire(){
    return arround(this.coutChantierTrilatte / this.solutionTradi.CAEntrepriseParM2);
  }
  get gainMaterieaux(){
    return arround(this.variation.margeMateriauxParM2 - this.resultat.margeMateriauxTradi);
  }
  get gainClient(){
    return arround(this.coutChantierTradi - this.coutChantierTrilatte);
  }
  get gainTemps(){
    // let total = arround(this.solutionTradi.totalTmpsMiseEnOeuvre - this.totalTmpsMiseEnOeuvreTrilatte);
    // let min = Math.ceil(total/60);
    // let hours = Math.floor((total - min) / 60);
    // return `${hours}h${ Math.abs(min) }min`;

    let time = Math.round(this.solutionTradi.totalTmpsMiseEnOeuvre) - Math.round(this.totalTmpsMiseEnOeuvreTrilatte);
    // var days = Math.round(time / 1440);
    let str = [];
    // if(days > 0){
    //   time -= days * 1440;
    //   const txt = (days > 1) ? 'jours' : 'jour';
    //   str.push(`${days} ${txt}`);
    // }


    var hours = Math.floor(time / 60);
    if(hours>0){
      time -= hours * 60;
      str.push(`${hours} h`);
    }

    var minutes = Math.floor(time);
    if(minutes>0){
      time -= minutes;
      str.push(`${minutes} min`);
    }

    // const hours = Math.floor(time/60);
    // const min = time - hours * 60;
    return str.join(' ');

  }
}


function arround(number){
    const num = Number(number);
    const rounded = num.toFixed(2);
    if (isNaN(rounded)) {
      return 0;
    }
    return (rounded < 0) ? 0 : rounded;
}

function minuteTocentieme(number){
  return arround(number / 60);
}




export {
    ObjFournitureMO,
    ObjDefault,
    ObjClient,
    DataTraditonelle,
    DataTrilatte,
    DataComparatifs,
    DataRendement,
    // DataAjustement,
    DataConclusion,
}

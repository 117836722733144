import {TimelineLite,TweenLite,CSSPlugin,EasePack} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

import Modernizr from 'Lib/modernizr.js';
import $ from 'jquery';
import _ from 'underscore';

import Application from 'App/Application';

$(() => {
    new Application();
});

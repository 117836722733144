import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Accordeon from "App/components/Accordeon";

export default class calcul_trilatte extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();
  }

  get index(){
    return 3;
  }


  get className(){
    return "Page Page--calculTrilatte";
  }

  render () {
    const html = $('script[name="calcul_trilatte"]').html();
    this.$el.html(html);

    this.$('.accordeon').each(function(){
      new Accordeon($(this));
    })
  }


}

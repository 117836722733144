import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Backbone from "backbone"

export default class Comparatifs extends PageAbstract {
  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();
  }

  get index(){
    return 4;
  }


  get className(){
    return "Page Page--comparatifs";
  }

  render () {
    const html = $('script[name="comparatifs"]').html();
    this.$el.html(html);
  }


}

import $ from 'jquery';
import Backbone from "backbone";
import {TimelineLite,TweenLite,CSSPlugin,EasePack} from 'gsap';
import Data from 'App/data/Data';
export default class PageAbstract extends Backbone.View {

  show(){
    return new TimelineLite()
      .from(this.$el, .9, {x:"100%", force3D:true, ease:Power3.easeInOut,onComplete:()=>{
        $('body').css('overflow','inherit');
        // this.$el.css('position','relative');
      }})
    ;
  }

  showReverse(){
    return new TimelineLite()
      .from(this.$el, .9, {x:"-100%", force3D:true, ease:Power3.easeInOut,onComplete:()=>{
        $('body').css('overflow','inherit');

        // this.$el.css('position','relative');
      }})
    ;
  }

  hide(){
      return new TimelineLite({
        onStart:()=>{
          $('body').css('overflow','hidden');
        },
        onComplete:()=>{
          this.$el.remove();}
      })
        .to(this.$el, .9, {x:"-100%", force3D:true, ease:Power3.easeInOut},'+=0.3')
      ;
  }

  hideReverse(){
      return new TimelineLite({
        onStart:()=>{
          $('body').css('overflow','hidden');
        },
        onComplete:()=>{
          this.$el.remove();}
      })
        .to(this.$el, .9, {x:"100%", force3D:true, ease:Power3.easeInOut},'+=0.3')
      ;
  }
  get events(){
    return {
      "change [data-id]" : "updateData",
      // "keyup [data-id]" : "updateData",
    };
  }

  updateData(e){
    const $el = $(e.target);
    const dataID = $el.attr("data-id");


    // if(!dataID){return;}
    const val = $el.val();
    // console.log(val);
    Data.set(dataID,val);

    this.$("[data-id]").each(function(){
      const $this = $(this);
      const dataID = $this.attr("data-id");
      const dataValue = Data.get(dataID);
      const data = (typeof dataValue == "number" ) ? Number(dataValue).toLocaleString().replace(/,/g, '.') : dataValue ;
      if(($this.is('input') || $this.is('select'))){
        $this.val(dataValue);
      }else{
        $this.text(data);
      }
    });

  }
  initGlobalData(){
    const refthis = this;
    this.$("[data-id]").each(function(){
      const $this = $(this);
      const dataID = $this.attr("data-id");
      const dataValue = Data.get(dataID);
      const data = (typeof dataValue == "number" ) ? Number(dataValue).toLocaleString().replace(/,/g, '.') : dataValue ;

      if(($this.is('input') || $this.is('select'))){
        $this.val(dataValue);
      }else{
        $this.text(data)
      }
    });
  }
}

import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Accordeon from "App/components/Accordeon";
import Data from 'App/data/Data';

export default class CalculTradi extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;
    this.initGlobalData();
  }

  get index(){
    return 2;
  }

  get className(){
    return "Page Page--CalculTradi";
  }

  render () {
    const html = $('script[name="calcul_traditionelle"]').html();
    this.$el.html(html);

    this.$('.accordeon').each(function(){
      new Accordeon($(this));
    })
  }
}

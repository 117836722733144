import $ from "jquery";
import _ from 'underscore';
import PageAbstract from "App/views/PageAbstract";
import Accordeon from "App/components/Accordeon";

export default class Reglages extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;

    this.initGlobalData();

  }

  get index(){
    return 7;
  }

  get className(){
    return "Page Page--reglages";
  }

  render () {
    const html = $('script[name="reglages"]').html();
    this.$el.html(html);
    this.$('.accordeon').each(function(){
      new Accordeon($(this));
    })
  }

  afterRender(){
    // let tabData = ['fibreBois','laineVerre','membrane','placo'];
    // tabData.forEach((e)=>{
    //   this.initGlobalData(e);
    // });
}


}

import $ from "jquery";
import { ObjFournitureMO, ObjDefault, ObjClient, DataTraditonelle, DataTrilatte, DataComparatifs, DataRendement, DataAjustement, DataConclusion } from 'App/data/Items';




class Data{
  get fibreBois(){
    return fibreBois;
  }
  get laineVerre(){
    return laineVerre;
  }
  get membrane(){
    return membrane;
  }
  get placo(){
    return placo;
  }
  get bois(){
    return bois;
  }
  get pointes(){
    return pointes;
  }
  get ecran(){
    return ecran;
  }
  get liteaux(){
    return liteaux;
  }
  get platre(){
    return platre;
  }
  get accessoires(){
    return accessoires;
  }
  get client(){
    return client;
  }
  get surfaceChantier(){
    return surfaceChantier;
  }
  get solutionTradi(){
    return solutionTradi;
  }
  get solutionTrilatte(){
    return solutionTrilatte;
  }
  get resultat(){
    return resultat;
  }
  get variation(){
    return variation;
  }
  get conclusion(){
    return conclusion;
  }

  get(key){
    const tab = key.split(".");
    return tab.reduce((a, b)=> {
      return a[b];
    }, this);
  }

  set(key, value){
    const tab = key.split(".");
    const lastKey = tab.splice(-1, 1);
    const data = tab.reduce((a, b)=>{
      return a[b];
    }, this);
    data[lastKey] = value;
  }
}



const fibreBois = new ObjFournitureMO(26, 10);
const laineVerre = new ObjFournitureMO(6.80, 10);
const membrane = new ObjFournitureMO(6, 7);
const placo = new ObjFournitureMO(12, 20);

const bois = new ObjDefault(310);
const pointes = new ObjDefault(2);
const ecran = new ObjDefault(0.80);
const liteaux = new ObjDefault(0.40);
const platre = new ObjDefault(57.50);
const accessoires = new ObjDefault(4);


const client = new ObjClient(43,8,15);
const surfaceChantier = new ObjDefault(130);
const solutionTradi = new DataTraditonelle(fibreBois,laineVerre,membrane,placo,bois,pointes,ecran,liteaux,platre,accessoires,client,surfaceChantier);
const solutionTrilatte = new DataTrilatte(platre,accessoires,client);
const resultat = new DataComparatifs(solutionTradi,solutionTrilatte,membrane,surfaceChantier);
const variation = new DataRendement(client,solutionTrilatte,platre,accessoires,surfaceChantier);
// const ajustement = new DataAjustement(variation,client,solutionTrilatte,platre,accessoires,surfaceChantier);

const conclusion = new DataConclusion(resultat,surfaceChantier,client,solutionTradi,solutionTrilatte,variation);




export default new Data();
/*/
class Data{
  constructor(){

    this._data = {
      fibreBois : {
        fourniture : 26.00,
        mo: 10.00,
      },
      laineVerre : {
        fourniture : 5.60,
        mo: 10.00,
      },
      membrane : {
        fourniture : 6.00,
        mo: 7.00,
      },
      placo : {
        fourniture : 24.00,
        mo: 8.00,
      },
      pointes : 2.00,
      ecran : 0.80,
      liteaux : 0.40,
      platreR6 : 54.00,
      Accessoires : 3.00
    }

  }


  get data(){
    return this._data;
  }

  setFournitureFibreBois(value){

  }

  get(key){
    const tab = key.split(".");
    return tab.reduce((a, b)=> {
      return a[b];
    }, this._data);
  }

  set(key, value){
    const tab = key.split(".");
    const lastKey = tab.splice(-1, 1);
    const data = tab.reduce((a, b)=>{
      return a[b];
    }, this._data);
    data[lastKey] = value;
  }

  getTotal(){

  }




}

export default new Data();/**/

import $ from "jquery";
import PageAbstract from "App/views/PageAbstract";
import Tabs from "App/components/Tabs";

export default class Rendement extends PageAbstract {

  initialize(){
    this.render();
    const refthis = this;

    this.initGlobalData();

  }

  get index(){
    return 5;
  }

  get className(){
    return "Page Page--rendement";
  }

  render () {
    const html = $('script[name="rendement"]').html();
    this.$el.html(html);
    this.$('.tabs').each(function(){
      new Tabs($(this));
    })

    // this.$('.accordeon').each(function(){
    //   new Accordeon($(this));
    // })

  }


}

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
//
// // Vue


class Router extends Backbone.Router {

  get routes() {
    return {
      "": "home",    // #
      ":page": "page",  // #client
      // "calcul-traditionelle": "calculTradi", // #calcul-traditionelle
      // "calcul-trilatte": "calculTrilatte", // #calcul-trilatte
      // "resultats-comparatifs": "comparatifs", // #resultats-comparatifs
      // "rendement": "rendement", // #rendement
      // "ajustement": "ajustement", // #ajustement
      // "conclusion": "conclusion", // #conclusion
      // "reglages": "reglages" // #reglages

    };
  }


  initialize() {
    this.navigate("");
  }

  home(){

  }

  page(page){
    this._pageName = page;
  }

  get pageName(){
    return this._pageName;
  }

  // page(pageName){
  //   let view;
  //   switch(pageName){
  //     case "Home" :
  //     case "Home" :
  //     case "Home" :
  //     case "Client" :
  //       eval ("view = new " + pageName + "()");
  //       break;
  //   }
  //
  // }
  // home () {
  //   const view = new Home();
  //   $('#app').html(view.render().$el);
  // }
  //
  // client () {
  //   const view = new Client();
  //   $('#app').html(view.render().$el);
  // }
  //
  // calculTradi(){
  //   const view = new CalculTradi();
  //   $('#app').html(view.render().$el);
  // }
  //
  // calculTrilatte(){
  //   const view = new calculTrilatte();
  //   $('#app').html(view.render().$el);
  // }
  //
  // comparatifs(){
  //   const view = new Comparatifs();
  //   $('#app').html(view.render().$el);
  // }
  //
  // rendement(){
  //   const view = new Rendement();
  //   $('#app').html(view.render().$el);
  // }
  //
  // ajustement(){
  //   const view = new Ajustement();
  //   $('#app').html(view.render().$el);
  // }
  //
  // conclusion(){
  //   const view = new Conclusion();
  //   $('#app').html(view.render().$el);
  // }
  //
  // reglages(){
  //   const view = new Reglages();
  //   $('#app').html(view.render().$el);
  // }

}

export default new Router();
